import React, { useState, useEffect } from 'react'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import EditableRegionFormOverview from './editableRegionFormOverview'
import EditableRegionFormValidation from './editableRegionFormValidation'
import EditableRegionFormOptions from './editableRegionFormOptions'
import EditableRegionFormOptionsLarge from './editableRegionFormOptionsLarge'
import EditableRegionFormMerge from './editableRegionFormMerge'
import EditableRegionFormPreview from './editableRegionFormPreview'
import AdminCard from './adminCard'
import ActionBar from './actionBar'
import EditableRegionPrePopulate from './editableRegionPrePopulate'

import {
  checkNameIsValid,
  checkInputTypeIdIsValid,
  checkEditableIsValid,
  checkMinLengthIsValid,
  checkMaxLengthIsValid,
  checkOptionsAreValid,
} from '../services/editableregion.service'
import editabletypesConstants from '../constants/editabletypes.constants'

const EditableRegionForm = ({
  editableRegion,
  mergeFields,
  currentEditables,
  onSubmit,
}) => {
  const [currentEditableRegion, setCurrentEditableRegion] = useState(
    editableRegion
  )
  const [isValid, setIsValid] = useState(false)
  const [isEditMode] = useState(editableRegion.id > 0)
  const [steps, setSteps] = useState([])
  const [activeStep, setActiveStep] = useState(0)
  const [isActiveStepValid, setIsActiveStepValid] = useState(false)

  const [prePopulatedHtml, setPrePopulatedHtml] = useState()

  useEffect(() => {
    setPrePopulatedHtml(editableRegion.prePopulatedState)
  }, [editableRegion])

  useEffect(() => {
    setCurrentEditableRegion({
      ...currentEditableRegion,
      prePopulatedState: prePopulatedHtml
    })
  }, [prePopulatedHtml])

  useEffect(
    () =>
      setIsValid(checkEditableIsValid(currentEditableRegion, currentEditables)),
    [currentEditableRegion]
  )

  useEffect(() => {
    let steps = []
    switch (currentEditableRegion.inputTypeId) {
      case 2:
      case 3:
        steps = !isEditMode
          ? ['Create an editable', 'Select merge field', 'Preview and confirm']
          : ['Update an editable', 'Update merge field', 'Preview and confirm']
        break
      case 4:
      case 6:
        steps = !isEditMode
          ? ['Create an editable', 'Select options', 'Preview and confirm']
          : ['Update an editable', 'Select options', 'Preview and confirm']
        break
      case 1:
      case 7:
        steps = !isEditMode
          ? [
            'Create an editable',
            'Set character limit',
            'Select merge field',
            'Preview and confirm',
          ]
          : [
            'Update an editable',
            'Update character limit',
            'Update merge field',
            'Preview and confirm',
          ]
        break
      case 5:
        steps = !isEditMode
          ? ['Create an editable', 'Pre-populate text', 'Preview and confirm']
          : ['Update an editable', 'Pre-populate text', 'Preview and confirm']
        break
      default:
        steps = ['Create an editable']
    }
    setSteps(steps)
  }, [isEditMode, currentEditableRegion.inputTypeId])

  useEffect(() => setIsActiveStepValid(validateActiveStep()), [
    currentEditableRegion,
    activeStep,
  ])

  const validateActiveStep = () => {
    if (activeStep === 0) {
      var nameValidity = checkNameIsValid(
        currentEditableRegion.name,
        currentEditables
      )
      return (
        nameValidity.isValid &&
        checkInputTypeIdIsValid(currentEditableRegion.inputTypeId)
      )
    }

    if (currentEditableRegion.inputTypeId === 1 || currentEditableRegion.inputTypeId === 7)
      if (activeStep === 1)
        return (
          checkMinLengthIsValid(
            currentEditableRegion.inputTypeId,
            currentEditableRegion.minLength,
            currentEditableRegion.maxLength
          ) &&
          checkMaxLengthIsValid(
            currentEditableRegion.inputTypeId,
            currentEditableRegion.minLength,
            currentEditableRegion.maxLength
          )
        )

    if (currentEditableRegion.inputTypeId === 1 || currentEditableRegion.inputTypeId === 7)
      if (activeStep === 2) return true //NO VALIDATION ON MERGE FIELD

    if (currentEditableRegion.inputTypeId === 4)
      if (activeStep === 1)
        return checkOptionsAreValid(
          currentEditableRegion.inputTypeId,
          currentEditableRegion.editableRegionOptions
        )

    if (currentEditableRegion.inputTypeId === 6)
      if (activeStep === 1)
        return checkOptionsAreValid(
          currentEditableRegion.inputTypeId,
          currentEditableRegion.editableRegionOptions
        )

    if (currentEditableRegion.inputTypeId === 5) return true

    if ([editabletypesConstants.DatePicker, editabletypesConstants.TimePicker].includes(currentEditableRegion.inputTypeId)) {
      if (activeStep === 1) return true
    }

    return false
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    if (isValid) {
      onSubmit({
        ...currentEditableRegion,
        mergeFieldId:
          currentEditableRegion.mergeFieldId === 0
            ? null
            : currentEditableRegion.mergeFieldId, //SET TO NULL IF 0
      })
    }
  }

  return (
    <>
      <AdminCard>
        <Stepper activeStep={activeStep}>
          {steps.map(label => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </AdminCard>
      <form onSubmit={handleOnSubmit}>
        {activeStep === 0 && (
          <EditableRegionFormOverview
            id={currentEditableRegion.id}
            name={currentEditableRegion.name}
            nameChanged={val =>
              setCurrentEditableRegion({ ...currentEditableRegion, name: val })
            }
            inputTypeId={currentEditableRegion.inputTypeId}
            typeChanged={val =>
              setCurrentEditableRegion({
                ...currentEditableRegion,
                inputTypeId: val,
              })
            }
            description={currentEditableRegion.description}
            descriptionChanged={description =>
              setCurrentEditableRegion({
                ...currentEditableRegion,
                description,
              })
            }
            testData={currentEditableRegion.testData}
            testDataChanged={testData =>
              setCurrentEditableRegion({
                ...currentEditableRegion,
                testData,
              })
            }
            minLength={currentEditableRegion.minLength}
            maxLength={currentEditableRegion.maxLength}
            isEditMode={isEditMode}
            isMandatory={currentEditableRegion.isMandatory}
            isMandatoryChanged={val =>
              setCurrentEditableRegion({
                ...currentEditableRegion,
                isMandatory: val
              })}
            isArchived={currentEditableRegion.isArchived}
            isArchivedChanged={val =>
              setCurrentEditableRegion({
                ...currentEditableRegion,
                isArchived: val,
              })
            }
            currentEditables={currentEditables}
          />
        )}
        {(currentEditableRegion.inputTypeId === 1 || currentEditableRegion.inputTypeId === 7) && activeStep === 1 && (
          <EditableRegionFormValidation
            inputTypeId={currentEditableRegion.inputTypeId}
            minLength={currentEditableRegion.minLength}
            minLengthChanged={val =>
              setCurrentEditableRegion({
                ...currentEditableRegion,
                minLength: parseInt(val),
              })
            }
            maxLength={currentEditableRegion.maxLength}
            maxLengthChanged={val =>
              setCurrentEditableRegion({
                ...currentEditableRegion,
                maxLength: parseInt(val),
              })
            }
          />
        )}
        {currentEditableRegion.inputTypeId === 4 && activeStep === 1 && (
          <EditableRegionFormOptions
            id={currentEditableRegion.id}
            options={currentEditableRegion.editableRegionOptions}
            optionsChanged={val =>
              setCurrentEditableRegion({
                ...currentEditableRegion,
                editableRegionOptions: val,
              })
            }
          />
        )}
        {currentEditableRegion.inputTypeId === 6 && activeStep === 1 && (
          <EditableRegionFormOptionsLarge
            id={currentEditableRegion.id}
            options={currentEditableRegion.editableRegionOptions}
            optionsChanged={val =>
              setCurrentEditableRegion({
                ...currentEditableRegion,
                editableRegionOptions: val,
              })
            }
          />
        )}
        {(((currentEditableRegion.inputTypeId === 1 || currentEditableRegion.inputTypeId === 7) && activeStep === 2)
          || ([editabletypesConstants.DatePicker, editabletypesConstants.TimePicker].includes(currentEditableRegion.inputTypeId)
            && activeStep === 1)) && (
            <EditableRegionFormMerge
              mergeFields={mergeFields}
              mergeFieldId={currentEditableRegion.mergeFieldId}
              mergeFieldIdChanged={val =>
                setCurrentEditableRegion({
                  ...currentEditableRegion,
                  mergeFieldId: parseInt(val),
                })
              }
            />
          )}
        {currentEditableRegion.inputTypeId === 5 && activeStep === 1 && (
          <EditableRegionPrePopulate
            html={prePopulatedHtml}
            htmlChanged={setPrePopulatedHtml}
          />
        )}
        {currentEditableRegion.inputTypeId > 0 &&
          isValid &&
          activeStep === steps.length - 1 && (
            <EditableRegionFormPreview
              editableRegion={currentEditableRegion}
              prePopulatedState={prePopulatedHtml}
            />
          )}
        <ActionBar
          leftContent={[
            {
              label: 'Back',
              show: activeStep !== 0,
              isPrimaryAction: false,
              onClick: () =>
                setActiveStep(prevActiveStep => prevActiveStep - 1),
            },
            {
              label: 'Next',
              show: activeStep !== steps.length - 1,
              isPrimaryAction: false,
              disabled: !isActiveStepValid,
              onClick: () =>
                setActiveStep(prevActiveStep => prevActiveStep + 1),
            },
          ]}
          rightContent={[
            {
              label: isEditMode ? 'Update' : 'Create',
              show: activeStep === steps.length - 1,
              isPrimaryAction: true,
              disabled: !isValid,
              type: 'submit',
            },
          ]}
        />
      </form>
    </>
  )
}

export default EditableRegionForm
