export const makeEditableValid = editable => {
  editable.classList.remove('optional')
  editable.classList.remove('invalid')
  editable.classList.add('valid')
}

export const makeEditableInvalid = editable => {

  editable.classList.remove('valid')
  editable.classList.add('invalid')
  const isMandatory = editable.getAttribute('is-mandatory')
  if(!isMandatory) {
    makeEditableOptional(editable)
  } 
}

export const makeEditableOptional = editable => {
  editable.classList.remove('valid')
  editable.classList.remove('invalid')
  editable.classList.add('optional')
}

const makeEditableHidden = editable => {
  editable.classList.remove('optional')
  editable.classList.add('valid')
  editable.innerText = ' '
  editable.classList.remove('valid')
  editable.classList.add('hidden')
    if (editable.nextElementSibling?.nodeName === 'BR') {
      var lineBreakElement = editable.nextElementSibling
      lineBreakElement.parentNode.removeChild(lineBreakElement)
    }
}

export const createNewPage = ({
  isFirstPage,
  content,
  header,
  footer,
  fontSize,
  fontFamily,
}) => {
  // create new page
  const newPage = document.createElement('div')
  newPage.className = 'page'
  newPage.style.fontFamily = fontFamily
  newPage.style.fontSize = fontSize

  // set header html
  const headerDiv = document.createElement('div')
  headerDiv.className = 'page-header'
  headerDiv.innerHTML = isFirstPage ? header || '' : ''

  // set body content
  const bodyDiv = document.createElement('div')
  bodyDiv.className = 'page-body'
  const bodyContent = document.createElement('div')
  bodyContent.className = 'page-body-content'

  if (typeof content == 'string') {
    bodyContent.innerHTML = content
  } else if (Array.isArray(content)) {
    content.forEach(r => bodyContent.appendChild(r))
  }

  //set footer html
  const footerDiv = document.createElement('div')
  footerDiv.className = 'page-footer'
  footerDiv.innerHTML = footer || ''

  // append page sections
  newPage.appendChild(headerDiv)
  bodyDiv.appendChild(bodyContent)
  newPage.appendChild(bodyDiv)
  newPage.appendChild(footerDiv)
  return newPage
}

const breakDownContent = (elementContent, bodyHeight) => {

  if (elementContent.offsetHeight <= bodyHeight) {
      return;
  }

  let elementsToFlatten = []

  if (elementContent.children && elementContent.children.length > 0) {
    for (let c = 0; c < elementContent.children.length; c++) {
      let child = elementContent.children[c]
      if (child.offsetHeight > bodyHeight && child.children.length > 1) {
        breakDownContent(child, bodyHeight)
        elementsToFlatten.push(child)
      }
    }
  }

  if (elementsToFlatten.length > 0) {
    for (let p = 0; p < elementsToFlatten.length; p++) {
      for (let c = 0; c < elementsToFlatten[p].children.length; c++) {
        let clone = elementsToFlatten[p].children[c].cloneNode(true);
        elementsToFlatten[p].parentNode.insertBefore(clone, elementsToFlatten[p]);
      }
      elementsToFlatten[p].parentNode.removeChild(elementsToFlatten[p])
    }
  }
}

export const getOverflowContent = page => {
  let removed = []

  const pageBody = page.children[1]
  const pageBodyContent = pageBody.children[0]

  let bodyHeight = pageBody.offsetHeight;

  breakDownContent(pageBodyContent, bodyHeight)

  while (pageBodyContent.offsetHeight > bodyHeight) {
    if (pageBodyContent.children.length > 1) {
      removed.unshift(
        pageBodyContent.children[pageBodyContent.children.length - 1]
      )
      pageBodyContent.removeChild(
        pageBodyContent.children[pageBodyContent.children.length - 1]
      )
    } else {
      //only one child left, the child is too large to fit on the page
      break
    }
  }

  return removed
}

export const getDocumentHTML = pagesContainer => {
  let html = ''
  const pages = Array.from(pagesContainer.children)
  pages.forEach(page => {
    const pageBody = page.children[1]
    const pageBodyContent = pageBody.children[0]
    html = html.concat(pageBodyContent.innerHTML)
  })
  return html
}

export const addEditableClickHandlers = (editablesNodeList, editableLookups, handleClick) => {
  const editablesArray = Array.from(editablesNodeList);

  return editablesArray.map((e, index) => {
    e.addEventListener('click', handleClick);

    // Set tabIndex for focusable navigation
    e.setAttribute('tabIndex', `${index + 1}`);

    // Set ARIA attributes and role for accessibility screen readers 
    const dataTagValue = e.getAttribute('data-tag-id');
    const label = editableLookups[dataTagValue]?.name || 'item';
    e.setAttribute('role', 'button');
    e.setAttribute('aria-label', `${label}`);
    e.setAttribute('aria-haspopup', 'dialog');

    //Added custom event listener so that it is possible to select the editables using just the keyboard
    e.addEventListener('keydown', (event) => {
      if (event.key === ' ' || event.key === 'Spacebar' || event.key === 'Enter') {
        event.preventDefault();  // Prevent the default browser action (scrolling)
        handleClick(event);
      }
    })

    return e;
  });
};


export const removeNonMandatoryFieldsOnCompletion = () => {
  const nonMandatoryFields = Array.from(document.querySelectorAll('.optional'))
    nonMandatoryFields.map(x=> makeEditableHidden(x))
}

export const getHtmlForEditableId = (editablesNodeList, editableId) => {
  if (!editablesNodeList) return

  const editablesArray = Array.from(editablesNodeList)

  const editable = editablesArray.find(
    e => parseInt(e.getAttribute('data-tag-id')) === editableId
  )

  if (!editable) {
    console.error(
      `Expected to find editable with id:${editableId} but does not exist in document`
    )
    return null
  }

  return editable.innerHTML
}

export const appendPage = (pageContainer, newPage) => {
  if (!pageContainer) return
  pageContainer.appendChild(newPage)
}
